import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import moment from 'moment'
import ReactPlayer from 'react-player'
import DateTimePicker from 'react-datetime-picker'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Player } from '../components'
import { container, padding, button, bgImage, type } from '../styles/global'
import { media, useBreakpoint, isClient } from '../styles/utils'
import { formatDate, msToTime } from '../utils'

import { data } from '../data/home'
import { playerContext, isEventActive } from '../components/Player/utils'

const Index = (props) => {
    const { status, setStatus } = useContext(playerContext)
    const [eventActive, setEventActive] = React.useState(isEventActive());

    // Check if event is active 
 
    useInterval(() => {
        //console.log('checking', isEventActive())
        setEventActive(isEventActive())
    }, 1000)

    const renderEventStatusText = () => {
        let statusText;

        if (eventActive) return (
            <Heading>
                Now Playing
            </Heading>
        )

        if (!eventActive && status !== 'finished') return (
            <Heading>
                Starting soon
            </Heading>
        )

        if (status == 'finished') return (
            <Heading>
                Event ended
            </Heading>
        )
    }

    const renderStatus = () => {
        return (
            <Status>
                <Container>
                    <Heading>
                        {renderEventStatusText()}
                    </Heading>
                    <Icon
                        src={require('../assets/images/header-logo.svg')}
                    />
                    <Icon
                        src={require('../assets/images/header-logo-text.svg')}
                    />
                </Container>
            </Status>
        )
    }

    const renderHero = () => {
        return (
            <Hero>
                <Container>
                    <Subheading
                        dangerouslySetInnerHTML={{__html: data.hero.subtitle}}
                    />

                    <Heading
                        dangerouslySetInnerHTML={{__html: data.hero.heading}}
                    />

                    <Player/>

                    {status !== 'finished' && (
                        <Text
                            dangerouslySetInnerHTML={{__html: data.hero.description}}
                        />
                    )}
                </Container>
            </Hero>
        )
    }

    const renderDetails = () => {
        const credits = data.eventDetails.credits;

        return (
            <EventDetails>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.eventDetails.datesPrice}}
                    />

                    <Accessibility
                        dangerouslySetInnerHTML={{__html: data.eventDetails.accessibility}}
                    />

                    <Button
                        href={data.eventDetails.findOutMore.link}
                        target={'_blank'}
                    >   
                        <span
                            dangerouslySetInnerHTML={{
                                __html: data.eventDetails.findOutMore.text,
                            }}
                        />
                    </Button>

                    <Credits>
                        <Subheading
                            dangerouslySetInnerHTML={{__html: credits.subtitle}}
                        />
                        <Heading
                            dangerouslySetInnerHTML={{__html: credits.heading}}
                        />
                        <Text
                            dangerouslySetInnerHTML={{__html: credits.otherCredits}}
                        />
                    </Credits>
                </Container>
            </EventDetails>
        )
    }

    const renderFooter = () => {
        return (
            <Footer>
                <Container>
                    <Icon src={require('../assets/images/footer-logos.svg')} />
                </Container>
            </Footer>
        )
    }

	return (
		<Layout>
            <Wrapper>
                {renderStatus()}
                {renderHero()}
                {renderDetails()}
                {renderFooter()}
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h3``
const Subheading = styled.h4``
const Text = styled.div``
const Info = styled.div``
const Button = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
    background: linear-gradient(180deg, #98DBCE 0%, #FFD800 53.65%, #EC008C 100%);
`

const Container = styled.div`
    ${container}
    ${padding}
    flex-direction: column;
    align-items: flex-start;
`

// Status   

const Status = styled.div`  
    display: flex;
    justify-content: center;
    width: 100%;

    ${Container} {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        // bottom border
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 24px; // same as padding 
            right: 24px; // same as padding
            display: flex;
            height: 1px;
            border-bottom: 1px solid white;
        }

        ${Heading} {
            ${type.heading2};
            color: white;
        }
    }
`


// Hero

const Hero = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    ${Container} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 41px;
        text-align: center;

        ${media.phone`
            padding-top: 24px;
        `}

        ${Subheading} {
            ${type.caption};
            color: white;
        }

        ${Heading} {
            ${type.heading1};
            color: white;
            margin-top: 13px;

            ${media.tablet`
                margin-top: 6px;
            `}
        }

        ${Text} {
            display: flex;
            flex-direction: column;
            max-width: 872px;
            margin-top: 43px;

            ${media.tablet`
                max-width: 630px;
            `}

            ${media.phone`
                margin-top: 26px;
            `}
            
            &, * {
                ${type.body}
            }

            span {
                color: white;
                margin-bottom: 0.7em;
            }
        }
    }
`


// Player

// see components/Player


// Event Details

const Accessibility = styled.div``
const Credits = styled.div``

const EventDetails = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    
    ${Container} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 82px;
        text-align: center;

        ${media.tablet`
            padding-top: 42px;
        `}

        > ${Heading}, ${Accessibility}, ${Button} {
            ${type.body}
            line-height: 1.1;

            ${media.phone`
                font-size: 16px;
            `}
        }

        // dates / price

        > ${Heading} {}

        ${Accessibility} {
            margin-top: 64px;

            ${media.phone`
                margin-top: 42px;
                max-width: 300px;
            `}
        }

        ${Button} {
            margin-top: 32px;
            padding: 9px 10px 13px;
            background: white;
            color: black;

            ${media.phone`
                margin-top: 25px;
                padding: 6px 6px 8px;
            `}
        }

        ${Credits} {
            margin-top: 64px;

            ${media.phone`
                margin-top: 48px;
            `}

            ${Subheading},
            ${Text} {
                &, * {
                    ${type.body};
                    line-height: 1.1;
                    color: black;

                    ${media.phone`
                        font-size: 18px;
                    `}
                }
            }

            ${Heading} {
                a {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 1.1;
                    margin-top: 5px;
                    color: black;

                    ${media.phone`
                        font-size: 24px;
                        margin-top: 2px;
                    `}
                }
            }

            ${Text} {
                margin-top: 32px;

                ${media.phone`
                    margin-top: 24px;
                `}

            }
        }
    }
`

const Footer = styled.div`
    padding-top: 90px;
    padding-bottom: 72px;

    ${Icon} {
        width: 403px;
        max-width: 100%;
    }

    ${media.phone`
        padding-top: 75px;
        padding-bottom: 32px;
    `}
`

export default Index
