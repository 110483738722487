import { stripIndent } from "common-tags";

export const data = {
    hero: {
        subtitle: `MONA FOMA AT CATARACT GORGE`,
        heading: stripIndent`
            AQUA LUMA<br>
            ROBIN FOX
        `,
        description: stripIndent`
            <span>Look up and listen.</span>
            Hit the button above to hear Robin Fox’s new electronic composition, set to subharmonic frequencies and twelve-metre-high water jets erupting in the Gorge. Overhead, lasers trace geometrical patterns in the mist and envelop you in a canopy of light and sound.
        `
    },
    eventDetails: {
        datesPrice: stripIndent`
            Friday 15–Sunday 17 January, 9.30–11.30pm<br>
            First Basin, Cataract Gorge, Launceston<br>
            <br>
            Free
        `,
        accessibility: `This venue is accessible via hard paths and a lift`,
        findOutMore: {
            text: 'Find out more about Mona Foma &rarr;',
            link: 'https://mofo.net.au/'
        },
        credits: {
            subtitle: `Audio-visual artist`,
            heading: `<a href="https://robinfox.com.au" target="_blank">Robin Fox</a>`,
            otherCredits: stripIndent`
                Web app & development<br>
                <a href="https://morebymore.com" target="_blank">More Studio</a>
            `,
        }
    },
}